<template>
  <y_layout>

    <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;">
      <el-page-header @back="$router.back()" content="商家卡券列表"></el-page-header>
      <div style="padding: 1rem;text-align: right">
        <el-button @click="dialogTableVisible=true" size="medium" type="primary" icon="el-icon-edit">新增</el-button>
      </div>
      <el-table v-loading="loading" :data="list" border style="width: 100%">
        <el-table-column prop="id" label="编号" width="80"></el-table-column>
        <el-table-column prop="title" label="名称" width="200"></el-table-column>
        <el-table-column prop="total_use" label="核销次数" width="80"></el-table-column>
        <el-table-column prop="fee" label="卡券面值" width="80"></el-table-column>
        <el-table-column prop="use_limit" label="核销限制" width="80"></el-table-column>
        <el-table-column prop="tips" label="使用门栏" width="200"></el-table-column>
        <el-table-column prop="total" label="发放数量" width="80"></el-table-column>
        <el-table-column label="开始日期" width="180">
          <template #default="s">
            {{s.row.time_start | date}}
          </template>
        </el-table-column>
        <el-table-column prop="time_end" label="截止日期" width="180">
          <template #default="s">
            {{s.row.time_end | date}}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="180">
          <template #default="s">
            {{s.row.created_at | date}}
          </template>
        </el-table-column>
        <el-table-column label="编辑时间" width="180">
          <template #default="s">
            {{s.row.updated_at | date}}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center" width="200">
          <template #default="scope">
            <el-button @click="edit(scope.row)" type="primary" size="small">编辑</el-button>
            <el-button @click="del(scope.row.id)" size="small">删除</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
    <el-dialog title="编辑卡券" destroy-on-close :visible.sync="dialogTableVisible" @close="resetSubmit">
      <div class="flex-def flex-zCenter">
        <el-form :model="form" label-width="5rem" style="width: 40rem">
          <el-form-item label="名称">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="图标">
            <y_upload_img v-model="form.pic"></y_upload_img>
          </el-form-item>
          <el-form-item label="核销次数">
            <el-input v-model="form.total_use"></el-input>
          </el-form-item>
          <el-form-item label="面值">
            <el-input v-model="form.fee">
              <template #append>元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="核销限制">
            <el-input v-model="form.use_limit">
              <template #append>天(两次核销间隔期限)</template>
            </el-input>
          </el-form-item>
          <el-form-item label="使用门栏">
            <el-input v-model="form.tips"></el-input>
          </el-form-item>
          <el-form-item label="使用期限">
            <el-date-picker
                v-model="range"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="卡券介绍">
            <y_editor v-model="form.desc" type="textarea"></y_editor>
          </el-form-item>
          <el-form-item align="right">
            <el-button @click="submit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
import y_upload_img from "@/components/y_upload_img";
import y_editor from "@/components/editor/y_editor";
export default {
  name: "coupon",
  components:{
    y_layout,
    y_upload_img,
    y_editor
  },
  data(){
    return{
      dialogTableVisible:false,
      list:[],
      page:1,
      total:0,
      range:[],
      form:{
        title:"",
        pic:"",
        total_use:1,
        fee:0,
        use_limit:0,
        tips:"",
        time_start:"",
        time_end:"",
        desc:"",
      },
      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    pageChange(e){
      this.page = e;
      this.load();
    },
    del(id){
      this.$api.coupon.del({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    submit(){
      this.form.time_start = this.range[0];
      this.form.time_end = this.range[1];
      this.form.total_use = parseInt(this.form.total_use);
      this.form.fee = parseFloat(this.form.fee);
      this.form.use_limit = parseInt(this.form.use_limit);
      if(!this.form.id){
        this.form.merchant_id = parseInt(this.$route.query.merchant_id);
        this.$api.coupon.add(this.form).then(()=>{
          this.$message.success("操作成功");
          this.dialogTableVisible = false;
          this.resetSubmit();
          this.load();
        })
        return;
      }
      this.$api.coupon.update(this.form).then(()=>{
        this.$message.success("操作成功");
        this.dialogTableVisible = false;
        this.resetSubmit();
        this.load();
      })
    },
    load(){
      this.loading = true;
      this.$api.coupon.page({
        page:this.page,
        merchant_id: parseInt(this.$route.query.merchant_id)
      }).then(res=>{
        this.total = res.total;
        this.list = res.list;
        this.loading = false;
      })
    },
    resetSubmit() {
      this.form = {
        title:"",
        total_use:1,
        fee:0,
        use_limit:0,
        tips:"",
        time_start:"",
        time_end:"",
        desc:"",
      };
      this.range = [];
    },
    edit(e){
      this.form = {
        ...e
      };
      this.range = [
          e.time_start,
          e.time_end,
      ]
      this.dialogTableVisible = true;
    }
  }
}
</script>

<style scoped>

</style>